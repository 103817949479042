
export const state = {
    company: JSON.parse(sessionStorage.getItem('inv-company')),
    companies: JSON.parse(sessionStorage.getItem('inv-companies')),
}

export const mutations = {
    // mutations for main company
    SET_CURRENT_COMPANY(state, newValue) {
        state.company = newValue
        saveState('inv-company', newValue)
    },

    INIT_COMPANY_SESSION(state, company) {
        state.company = company
    },

    REMOVE_COMPANY(state) {
        state.company = null
        saveState('inv-company', null)
    },

    // mutations for others companies
    SET_COMPANIES(state, companies) {
        state.companies = companies
        saveState('inv-companies', companies)
    },

    INIT_COMPANIES_SESSION(state, companies) {
        state.companies = companies
    },

    REMOVE_ALL_COMPANIES(state) {
        state.companies = null
        saveState('inv-companies', [])
    },

    REMOVE_ONE_COMPANY(state, id) {
        state.companies = state.companies.filter(company => company.id != id)
        saveState('inv-companies', state.companies)
    },

    UPDATE_SETTING(state, setting) {
        if(setting.companyId == state.company.id){
            state.company.setting = setting
            saveState('inv-company', state.company)
        }

        for(let company of state.companies){
            if(company.id == setting.companyId){
                company.setting = setting
            }
        }
        saveState('inv-companies', state.companies) 
    },

    UPDATE_COMPANY(state, company) {
        console.log(company)
        // if the updated company is the one in process now
        if(company.id == state.company.id){
            state.company = {...company, setting: state.company.setting}
            saveState('inv-company', {...company, setting: state.company.setting})
            console.log('state updated')
        }

        // we find the company to update
        const companyToUpdate = state.companies.find(item => item.id == company.id)
        // we remove that to the companies list
        const companies = state.companies.filter(item => item.id != company.id)

        // we add the modified one
        companies.push({...company, setting : companyToUpdate.setting})
        state.companies = companies
        saveState('inv-companies', companies) // we save all list
    },
}

export const getters = {
    // Whether the company is in the store.
    isCompanySet(state) {
        return !!state.company
    },

    // Whether the company is configured.
    isConfigured(state) {
        return !!state.company.isConfigured
    },

    // Whether the price system is set
    priceSystemSet(state) {
        return !!state.company.setting.priceSystemSet
    },
    // Whether the currency setting is set
    currencySet(state) {
        return !!state.company.setting.currencySet
    },
    // Whether the rate first setting is set
    rateSet(state) {
        return !!state.company.setting.rateSet
    },
    // retrieves the current company
    company(state) {
        return state.company
    },
    // retrieves all companies
    companies(state) {
        return state.companies
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        let company = window.sessionStorage.getItem('inv-company')
        let companies = window.sessionStorage.getItem('inv-companies')
        if (company) {
            dispatch('initCompanySession', company)
        }

        if (companies) {
            dispatch('initCompaniesSession', companies) 
        }
    },

    // actions for managing company state
    // init company in the store
    initCompanySession({ commit }, company){
        commit('INIT_COMPANY_SESSION', company)
    },

    // adds company in the store.
    addCompany({ commit }, company) {
        commit('SET_CURRENT_COMPANY', company)
    },

    // update company in the store.
    updateCompany({ commit }, company) {
        commit('UPDATE_COMPANY', company)
    },

    // Removes the company in the store.
    removeCompany({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('REMOVE_COMPANY' )
    },
    
    // actions for managing companies state
    // init companies in the store
    initCompaniesSession({ commit }, companies){
        commit('INIT_COMPANIES_SESSION', companies)
    },

    // adds companies in the store.
    addCompanies({ commit }, companies) {
        commit('SET_COMPANIES', companies)
    },

    // Removes the companies in the store.
    removeCompanies({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('REMOVE_ALL_COMPANIES', )
    },

    removeOneCompany({ commit }, id) {
        // eslint-disable-next-line no-unused-vars
        commit('REMOVE_ONE_COMPANY', id)
    },

    updateSetting({ commit }, setting) {
        // eslint-disable-next-line no-unused-vars
        commit('UPDATE_SETTING', setting)
    },

}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
