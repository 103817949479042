
export const state = {
    cart: JSON.parse(sessionStorage.getItem('inv-cart')),
}

export const mutations = {
    SET_CART(state, newValue) {
        state.cart = newValue
        saveState('inv-cart', newValue)
    },

    INIT_SESSION(state, cart) {
        state.cart = cart
    },
}

export const getters = {
    // Whether the user is currently logged in.
    cartInProcess(state) {
        return !!state.cart
    },

    cart(state) {
        return state.cart
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        let cart = window.sessionStorage.getItem('inv-cart')
        if (cart) {
            dispatch('initSession', cart)
        }
        
    },

    initSession({ commit }, cart){
        commit('INIT_SESSION', cart)
    },

    // Logs in the current user.
    setCart({ commit }, cart) {
        commit('SET_CART', cart)
    },

    // Logs out the current user.
    cleanCart({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CART', [])
    }

}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
