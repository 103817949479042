
export const state = {
    currentUser: JSON.parse(sessionStorage.getItem('inv-user')),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('inv-user', newValue)
    },

    INIT_SESSION(state, user) {
        state.currentUser = user
    },

    RESET_DATA(state, data) {
        state.passwordReset = data
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    user(state) {
        return state.currentUser
    },
    resetData(state) {
        return state.passwordReset
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        let user = window.sessionStorage.getItem('inv-user')
        if (user) {
            dispatch('initSession')
        }
        
    },

    initSession({ commit }, user){
        commit('INIT_SESSION', user)
    },

    // Logs in the current user.
    logIn({ commit }, user) {
        commit('SET_CURRENT_USER', user)
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)
    },

    resetPassword({ commit }, data) {
        commit('RESET_DATA', data)
    }

}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
