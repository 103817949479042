
export const state = {
    user: JSON.parse(sessionStorage.getItem('user-process')),
    company: JSON.parse(sessionStorage.getItem('company-process')),
    
    userAccount: JSON.parse(sessionStorage.getItem('user-account')),
}

export const mutations = {
    SET_USER_IN_PROCESS(state, newValue) {
        state.user = newValue
        saveState('user-process', newValue)
    },

    SET_COMPANY_IN_PROCESS(state, newValue) {
        state.company = newValue
        saveState('company-process', newValue)
    },

    SET_USER_ACCOUNT_REUNITIALISATION(state, newValue) {
        state.userAccount = newValue
        saveState('user-account', newValue)
    },

    INIT_USER_IN_PROCESS_SESSION(state, user) {
        state.user = user
    },

    INIT_COMPANY_IN_PROCESS_SESSION(state, company) {
        state.company = company
    }
}

export const getters = {
    userAccount(state){
        return state.userAccount
    },

    registrationInProcess(state) {
        return !!state.user
    },

    userInProcess(state) {
        return state.user
    },

    companyInProcess(state) {
        return state.company
    },
    
    // Whether the company is configured.
    isConfigured(state) {
        return !!state.company.isConfigured
    },

    // Whether the price system is set
    priceSystemSet(state) {
        return !!state.company.setting.priceSystemSet
    },
    // Whether the currency setting is set
    currencySet(state) {
        return !!state.company.setting.currencySet
    },
    // Whether the rate first setting is set
    rateSet(state) {
        return !!state.company.setting.rateSet
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        let user = window.sessionStorage.getItem('user-process')
        let company = window.sessionStorage.getItem('company-process')
        let userAccount = window.sessionStorage.getItem('user-account')
        if (user) {
            dispatch('initUserInProcessSession')
        }
        if (company) {
            dispatch('initCompanyInProcessSession')
        }
        
        if (userAccount) {
            dispatch('initUserAccountSession')
        } 
    },

    initUserInProcessSession({ commit }, user){
        commit('INIT_USER_IN_PROCESS_SESSION', user)
    },

    initCompanyInProcessSession({ commit }, user){
        commit('INIT_COMPANY_IN_PROCESS_SESSION', user)
    },

    initUserAccountSession({ commit }, user){
        commit('SET_USER_ACCOUNT_REUNITIALISATION', user)
    },

    // start a user process session.
    setUserInProcess({ commit }, user) {
        commit('SET_USER_IN_PROCESS', user)
    },

    // end a user process session.
    removeUserInProcess({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_USER_IN_PROCESS', null)
    },

    // start a company process session.
    setCompanyInProcess({ commit }, company) {
        commit('SET_COMPANY_IN_PROCESS', company)
    },

    // start a company process session.
    removeCompanyInProcess({ commit }) {
        commit('SET_COMPANY_IN_PROCESS', null)
    },

}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
