import store from "@/state/store";

export default [
  // resister user
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "dashboard" });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  // confirm-user
  {
    path: "/confirm-user",
    name: "confirm-user",
    component: () => import("../views/account/confirm-user.vue"),
    meta: {
      title: "Confirmation du compte",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (!store.getters["registration/registrationInProcess"]) {
        // Redirect to the home page instead
        next({ name: "login" });
      } else {
        next();
      }
    },
  },

  // choose subscription pack
  {
    path: "/choose-subscription-pack.html",
    name: "choose-pack",
    component: () => import("../views/account/choose-pack.vue"),
    meta: {
      title: "Choisir un abonnement",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (!store.getters["registration/registrationInProcess"]) {
        // Redirect to the home page instead
        next({ name: "login" });
      } else {
        next();
      }
    },
  },
  // resister conclusion
  {
    path: "/register-conclution.html",
    name: "register-conclusion",
    component: () => import("../views/account/register-conclusion.vue"),
    meta: {
      title: "Register conclusion",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next();
      } else {
        // Continue to the login page
        next({ name: "login" });
      }
    },
  },
  // init-password
  {
    path: "/initialisation_du_mot_de_passe.html",
    name: "init-password",
    component: () => import("../views/account/init-password.vue"),
    meta: {
      title: "Confirmation du compte",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (!store.getters["registration/registrationInProcess"]) {
        // Redirect to the home page instead
        next({ name: "login" });
      } else {
        next();
      }
    },
  },
  // company not configured
  {
    path: "/register",
    name: "company-not-configured",
    component: () => import("../views/account/companyNotConfigured.vue"),
    meta: {
      title: "Register",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "dashboard" });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  // login
  {
    path: "/",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    meta: {
      title: "Login",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (!store.getters["auth/loggedIn"] && !store.getters["company/isCompanySet"]) {
        // Continue to the login page
        next();
      } else {
        // Redirect to the home page instead
        next({ name: "dashboard" });
      }
    },
  },
  // choose company
  {
    path: "/choisir-magasins.html",
    name: "choose-company",
    component: () => import("../views/account/companies.vue"),
    meta: {
      title: "choisir le magasin",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"] && !store.getters["company/isCompanySet"]) {
        // Continue to the page
        next();
      } else {
        // Redirect to set-currency page instead
        next({ name: "dashboard" });
      }
    },
  },
  // create company
  {
    path: "/create-shop.html",
    name: "create-company",
    component: () => import("../views/account/create-company.vue"),
    meta: {
      title: "Création de votre magasin",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"] && !store.getters["registration/companyInProcess"]) {
        // Continue to 
        next();
      }
      else {
        // Continue to the login page
        next({name:'login'});
      }
    },
  },
  // setting tarification mode
  {
    path: "/tarification.html",
    name: "set-tarif-system",
    component: () => import("../views/account/set-tarif-system.vue"),
    meta: {
      title: "Reglage du mode de tarification",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['registration/companyInProcess']) {
        // Redirect to the home page instead
        next();
      } else {
        // Continue to the login page
        next({name:'create-company'});
      }
    },
  },
  // setting currencies
  {
    path: "/devises.html",
    name: "set-currency",
    component: () => import("../views/account/set-currency.vue"),
    meta: {
      title: "Reglage devises",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['registration/companyInProcess']) {
        // Redirect to the home page instead
        next();
      } else {
        // Continue to the login page
        next({name:'set-tarif-system'});
      }
    },
  },
  // setting rate
  {
    path: "/rate.html",
    name: "set-rate",
    component: () => import("../views/account/set-rate.vue"),
    meta: {
      title: "Reglage du taux de change",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"] && store.getters["registration/currencySet"]) {
        // Continue to the page
        next();
      } else {
        // Redirect to set-currency page instead
        next({ name: "set-currency" });
      }
    },
  },
  // setting finished
  {
    path: "/finreglage.html",
    name: "setting-success",
    component: () => import("../views/account/setting-conclusion.vue"),
    meta: {
      title: "Fin des reglages",
    },
    // beforeEnter(routeTo, routeFrom, next) {
    //   // If the user is already logged in
    //   if (store.getters["auth/loggedIn"] && store.getters["registration/rateSet"]) {
    //     // Continue to the page
    //     next();
    //   } else {
    //     // Redirect to set-currency page instead
    //     next({ name: "set-rate" });
    //   }
    // },
  },
  
  // forgot password
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password"
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "dashboard" });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  // confirm token
  {
    path: "/confirm-token",
    name: "confirm-token",
    component: () => import("../views/account/confirm-token.vue"),
    meta: {
      title: "Confirmation du numero de telephone",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "dashboard" });
      } else {
        next();
      }
    },
  },
  // reset password
  {
    path: "/reset-password/:id",
    name: "reset-password",
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "reunitialisation du mot de passe",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "dashboard" });
      } else {
        next();
      }
    },
  },

  // subscription
  {
    path: "/gettingStarted.html",
    name: "getting-started",
    component: () => import("../views/account/Subscrib.vue"),
    meta: {
      title: "inscription",
    },
    // beforeEnter(routeTo, routeFrom, next) {
    //   // If the user is already logged in
    //   if (store.getters["auth/loggedIn"]) {
    //     // Redirect to the home page instead
    //     next({ name: "dashboard" });
    //   } else {
    //     next();
    //   }
    // },
  },
  // subscription
  {
    path: "/subscription-expired.html",
    name: "subscription-expired",
    component: () => import("../views/account/subscription-expired.vue"),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        next();
      } else {
        next({name:'login'});
      }
    },
  },

  // dashboard
  {
    path: "/dashboard",
    meta: {
      title: "Dashboard",
      //authRequired: true,
    },
    component: () => import("../views/dashboard/router.vue"),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"] && store.getters["company/company"]) {
        // Redirect to the home page instead
        next();
      } else {
        next({name:'login'});
      }
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("../views/dashboard/home/index.vue"),
      },
      {
        path: "categories.html",
        name: "categories",
        component: () => import("../views/dashboard/category/index.vue"),
      },
      {
        path: "taxes.html",
        name: "taxes",
        component: () => import("../views/dashboard/tax/index.vue"),
      },
      {
        path: "promotions.html",
        name: "promotions",
        component: () => import("../views/dashboard/promotion/index.vue"),
      },
      {
        path: "remises.html",
        name: "discounts",
        component: () => import("../views/dashboard/discount/index.vue"),
      },
      // {
      //   path: "remises-article.html",
      //   name: "discounts-article",
      //   component: () => import("../views/dashboard/discount-article/index.vue"),
      // },
      {
        path: "profile.html",
        name: "profile",
        component: () => import("../views/dashboard/profile/index.vue"),
      },
      {
        path: "facturiers",
        name: "billers",
        component: () => import("../views/dashboard/biller/index.vue"),
      },
      {
        path: "facturiers/:id",
        name: "biller",
        component: () => import("../views/dashboard/biller/detail.vue"),
      },
      {
        path: "Articles",
        name: "articles",
        component: () => import("../views/dashboard/article/index.vue"),
      },
      {
        path: "Articles/:id",
        name: "article",
        component: () => import("../views/dashboard/article/detail.vue"),
      },
      {
        path: "aprovisionnements",
        name: "provisions",
        component: () => import("../views/dashboard/provision/index.vue"),
      },
      {
        path: "ajout-aprovisionnement.html",
        name: "provision-create",
        component: () => import("../views/dashboard/provision/create.vue"),
      },
      {
        path: "detail-aprovisionnement/:id",
        name: "provision-detail",
        component: () => import("../views/dashboard/provision/detail.vue"),
      },
      {
        path: "aprovisionnements/:id",
        name: "provision-update",
        component: () => import("../views/dashboard/provision/update.vue"),
      },
      // inventaire
      {
        path: "inventaires.html",
        name: "inventories",
        component: () => import("../views/dashboard/inventory/index.vue"),
      },
      {
        path: "ajout-inventaire.html",
        name: "inventory-create",
        component: () => import("../views/dashboard/inventory/create.vue"),
      },
      {
        path: "detail-inventory/:id",
        name: "inventory-detail",
        component: () => import("../views/dashboard/inventory/detail.vue"),
      },
      {
        path: "inventories/:id",
        name: "inventory-update",
        component: () => import("../views/dashboard/inventory/update.vue"),
      },
      // fin inventaire
      {
        path: "rapport.html",
        name: "reports",
        component: () => import("../views/dashboard/report/index.vue"),
      },
      {
        path: "factures.html",
        name: "invoices",
        component: () => import("../views/dashboard/invoice/index.vue"),
      },
      {
        path: "factures-creation.html",
        name: "invoice-create",
        component: () => import("../views/dashboard/invoice/create.vue"),
      },
      {
        path: "factures/:id",
        name: "invoice-detail",
        component: () => import("../views/dashboard/invoice/detail.vue"),
      },
      {
        path: "factures/:id/update.html",
        name: "invoice-update",
        component: () => import("../views/dashboard/invoice/update.vue"),
      },
      {
        path: "fournisseurs.html",
        name: "providers",
        component: () => import("../views/dashboard/provider/index.vue"),
      },
      {
        path: "clients.html",
        name: "clients",
        component: () => import("../views/dashboard/client/index.vue"),
      },
      {
        path: "magasins.html",
        name: "companies",
        component: () => import("../views/dashboard/company/index.vue"),
      },
      {
        path: "magasins/:id",
        name: "company",
        component: () => import("../views/dashboard/company/detail.vue"),
      },
      {
        path: "magasins/realtime.html",
        name: "companies-realtime",
        component: () => import("../views/dashboard/company/realtime.vue"),
      },
      // {
      //   path: "entreprises/:id",
      //   component: () => import("../views/dashboard/company/overview.vue"),
      //   children: [
      //     {
      //       path: "accueil.html",
      //       name: "company",
      //       component: () => import("../views/dashboard/company/pages/charts/index.vue"),
      //     },
      //     {
      //       path: "settings.html",
      //       name: "company-settings",
      //       component: () => import("../views/dashboard/company/pages/settings.vue"),
      //     },
      //   ]
      // },
    ]
  },

  // logout
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "dashboard" });
      } else {
        // Continue to the login page
        next();
      }
    },
    component: () => import("../views/auth/logout.vue")
  }
];